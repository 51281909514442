<template>
  <div class="membership template-1">
    <header class="page-header container">
      <h1>{{ translations.tcMembership }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" :i18n="translations.components" />
      <div class="page-body container mx-med">
        <section class="section-1">
          <h2>{{ translations.tcCandidates }}</h2>
          <div class="row">
            <div class="t-container col col-6 col-100-sd">
              <div class="p-2 bg-block">
                <div class="nonew" v-if="this.recent_prospects.length === 0">
                  {{ translations.tcNoRecentProspectsFound }}
                </div>
                <div v-else>
                  <b-table class="g-table" :fields="prospectFields" :items="recent_prospects" :per-page="perPage"
                    :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small>
                    <template v-slot:cell(prospect_name)="data">
                      <a @click.prevent="individualProspectStoreKeyAndRedirect(data.item)" href="#">
                        {{ data.item.prospect_name }}
                      </a>
                    </template>
                    <template v-slot:cell(add_date)="data">
                      {{ !!data.item.add_date ? data.item.add_date.substring(0, 10) : null }}
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="mt-3">
                <button class="btn btn-secondary btn-w-med mr-4 mt-3 mb-3" @click="sponsorCandidate()"
                  v-if="userLogin.membertype.toLowerCase() === 'usa'">
                  {{ translations.tcSponsorCandidate }}
                </button>
                <router-link to="/programs/mb/prospects" class="btn btn-primary btn-w-med mt-3 mb-3">
                  <div>{{ translations.tcViewMore }}</div>
                </router-link>
                <button class="btn btn-secondary btn-w-med mr-2 mt-3 mb-3" @click="handleSponsorAuxiliaryCandidate"
                  v-if="userLogin.membertype.toLowerCase() === 'usa'">
                  Sponsor an Auxiliary Candidate
                </button>
                <b-button :href="gideonMembershipApplication" download target="_blank"
                  class="btn btn-secondary btn-w-med mr-4 mt-3 mb-3" v-if="userLogin.membertype.toLowerCase() === 'usa'">
                  {{ translations.tcDownloadCandidateApplicationForm }}
                </b-button>
                <b-button :href="auxiliaryMembershipApplication" download target="_blank"
                  class="btn btn-secondary btn-w-med mr-1 mt-4 mb-4 btn-w-lg"
                  v-if="userLogin.membertype.toLowerCase() === 'usa'">
                  Download Auxiliary Candidate Application
                </b-button>
              </div>
            </div>
            <div class="col col-6 col-100-sd">
              <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-2">
          <h2>{{ translations.tcNewMembers }}</h2>
          <div class="row">
            <div class="t-container col col-6 col-100-sd">
              <div class="p-2 bg-block">
                <div class="nonew" v-if="this.recent_members.length === 0">
                  {{ translations.tcNoNewMembersFound }}
                </div>
                <div v-else>
                  <b-table class="g-table" :fields="memberFields" :items="recent_members" :per-page="perPage"
                    :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small>
                    <template v-slot:cell(sort_name)="data">
                      <a @click.prevent="individualMemberStoreKeyAndRedirect(data.item)" href="#">
                        {{ data.item.name }}
                      </a>
                    </template>
                    <template v-slot:cell(join_date)="data">{{
                      !!data.item.join_date ? data.item.join_date.substring(0, 10) : null
                    }}</template>
                  </b-table>
                </div>
              </div>
              <div class="mt-3">
                <router-link to="/programs/mb/member-list/new" class="btn btn-primary btn-w-med mt-3 mb-3">
                  <div>{{ translations.tcViewMore }}</div>
                </router-link>
              </div>
            </div>
            <div class="col col-6 col-100-sd">
              <NumberCard v-for="(stat, index) in bottomWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard v-for="(info, index) in membershipInfo" :key="index" :obj="info"
              @set_program="handleSetProgram($event)" />
          </div>
        </section>
      </div>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'membership',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      prospectFields: [],
      memberFields: [],
      perPage: 20,
      filter: null,
      sortBy: 'Name',
      sortDesc: false,
      membershipInfo: [],
    }
  },
  components: {
    campSelector: CampSelect,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearIndividualProfile: 'membership/clearIndividualProfile',
      clearProspectProfile: 'membership/clearProspectProfile',
      loadWidgetData: 'membership/loadWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async page_load() {
      await Promise.all([this.setLoadingStatus(true), await this.loadWidgetData()]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    individualMemberStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data.ind_key)
      this.$router.push('/programs/mb/member-profile/')
    },
    individualProspectStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data.prs_ind_key)
      this.$router.push('/programs/mb/prospect-profile/')
    },
    individualNonMemberStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data)
      this.$router.push('/programs/mb/non-member-profile')
    },
    sponsorCandidate() {
      this.clearIndividualProfile()
      this.clearProspectProfile()
      this.$router.push({ name: 'sponsorcandidate' })
    },
    handleSponsorAuxiliaryCandidate() {
      this.clearIndividualProfile()
      this.clearProspectProfile()
      this.$router.push({ name: 'sponsorauxiliarycandidate' })
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
    loadTranslations() {
      this.prospectFields = [
        { key: 'prospect_name', label: this.translations.tcCandidateName, sortable: true },
        { key: 'add_date', label: this.translations.tcAddDate, sortable: true },
      ]
      this.memberFields = [
        { key: 'sort_name', label: this.translations.tcMemberName, sortable: true },
        { key: 'join_date', label: this.translations.tcJoinDate, sortable: true },
      ]
      this.membershipInfo = [
        {
          class: 'col col-6',
          title: this.translations.tcMemberListTitle,
          content: this.translations.tcMemberListDescription,
          link: '/programs/mb/member-list',
          label: this.translations.tcViewMore,
          img: {
            src: 'svgs/church-list.svg',
            alt: this.translations.tcMemberListTitle,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: this.translations.tcCampOfficersTitle,
          content: this.translations.tcCampOfficersDescription,
          link: '/programs/mb/member-list/cof',
          label: this.translations.tcViewMore,
          img: {
            src: 'svgs/church-officers.svg',
            alt: this.translations.tcCampOfficersTitle,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: this.translations.tcMemberReportsTitle,
          content: this.translations.tcMemberReportsDescription,
          link: '/reports/report-center/mb',
          label: this.translations.tcViewMore,
          img: {
            src: 'svgs/member-reports.svg',
            alt: this.translations.tcMemberReportsTitle,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: this.translations.tcProgramResourcesTitle,
          content: this.translations.tcProgramResourcesDescription,
          emitlink: '/resources/media-portal/program',
          itemId: '5B592EEB-1E4A-4157-B4DB-A3564B7B2E6B', // The sitecore ItemId for Membership Program
          label: this.translations.tcViewMore,
          img: {
            src: 'svgs/program-resources.svg',
            alt: this.translations.tcProgramResourcesTitle,
            width: 64,
            height: 64,
          },
        },
      ]
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.perPage = constantData.per_page_default
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'number-card', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      bottomWidgetData: 'membership/bottomWidgetData',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      recent_members: 'membership/recent_members',
      recent_prospects: 'membership/recent_prospects',
      topWidgetData: 'membership/topWidgetData',
      userLogin: 'user/userLogin',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    gideonMembershipApplication() {
      return constantData.document_prefix + constantData.gideon_membership_application
    },
    auxiliaryMembershipApplication() {
      return constantData.document_prefix + constantData.auxiliary_membership_application
    },
  },
}
</script>

<style lang="scss">
.nonew {
  padding: 30px;
}

@import '@/styles/settings.scss';

.membership {
  .page-body {
    margin-top: 60px;

    h2 {
      margin: 0;
      margin-bottom: 32px;
      color: #000000;
      font-family: $DIN;
      font-size: 42px;
      letter-spacing: 1.4px;
      line-height: 44px;
      text-transform: uppercase;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .btn-w-lg {
      width: 500px;
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
